var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: _vm.header,
        close: _vm.callClose,
        "content-size": "xlarge",
      },
    },
    [
      _vm.step === 0
        ? _c("aave-table", {
            attrs: { title: _vm.borrowTitle },
            on: { selectedBorrow: _vm.handleSelectedBorrow },
          })
        : _vm._e(),
      _vm.step === 1
        ? _c("aave-amount-form", {
            attrs: {
              "selected-token": _vm.selectedToken,
              "action-type": _vm.borrowTitle,
              "show-toggle": _vm.aaveBorrowForm.showToggle,
              "left-side-values": _vm.aaveBorrowForm.leftSideValues,
              "right-side-values": _vm.aaveBorrowForm.rightSideValues,
              "form-text": _vm.aaveBorrowForm.formText,
              "button-title": _vm.aaveBorrowForm.buttonTitle,
              "token-balance": _vm.tokenBalance.toFixed(),
              "token-decimal": _vm.tokenDecimals,
            },
            on: { cancel: _vm.handleCancel, emitValues: _vm.handleValues },
          })
        : _vm._e(),
      _vm.step === 2
        ? _c("aave-select-interest", {
            attrs: { "selected-token": _vm.selectedTokenDetails },
            on: { continue: _vm.handleContinue },
          })
        : _vm._e(),
      _vm.step === 3 || _vm.step === 4
        ? _c("aave-summary", {
            attrs: {
              "selected-token": _vm.selectedToken,
              amount: _vm.amount,
              step: _vm.step,
              apr: _vm.apr,
              "action-type": _vm.borrowTitle,
              "amount-usd": _vm.amountUsd,
            },
            on: { onConfirm: _vm.handleConfirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }