var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: "Withdraw",
        "right-btn-text": "Close",
        close: _vm.close,
        "content-size": "xlarge",
      },
    },
    [
      _vm.step === 0
        ? _c("aave-amount-form", {
            attrs: {
              "selected-token": _vm.preSelectedToken,
              "show-toggle": _vm.aaveWithdrawForm.showToggle,
              "left-side-values": _vm.aaveWithdrawForm.leftSideValues,
              "right-side-values": _vm.aaveWithdrawForm.rightSideValues,
              "form-text": _vm.aaveWithdrawForm.formText,
              "button-title": _vm.aaveWithdrawForm.buttonTitle,
              "aave-balance": _vm.aaveWithdrawForm.depositedBalance,
              "token-decimal": _vm.aaveWithdrawForm.decimals,
            },
            on: {
              cancel: _vm.handleCancel,
              emitValues: _vm.handleWithdrawAmount,
            },
          })
        : _vm._e(),
      _vm.step === 1
        ? _c("aave-summary", {
            attrs: {
              "selected-token": _vm.preSelectedToken,
              amount: _vm.amount,
              "action-type": _vm.withdrawTitle,
              "amount-usd": _vm.amountUSD,
            },
            on: { onConfirm: _vm.handleConfirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }