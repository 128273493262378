var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "align-end justify-space-between" },
        [
          _vm.hasSearch
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("mew-search", {
                    attrs: {
                      placeholder: "Search Token Symbol",
                      value: _vm.searchInput,
                    },
                    on: { input: _vm.setSearchInput },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasToggle
            ? _c(
                "div",
                { staticClass: "align-end justify-center pa-3" },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: {
                        mandatory: "",
                        "active-class": "buttonToggleDark white--text",
                      },
                      model: {
                        value: _vm.toggleType,
                        callback: function ($$v) {
                          _vm.toggleType = $$v
                        },
                        expression: "toggleType",
                      },
                    },
                    [
                      _c("v-btn", { attrs: { small: "" } }, [_vm._v("All")]),
                      _c("v-btn", { attrs: { small: "" } }, [_vm._v("Stable")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("mew-table", {
                attrs: {
                  "has-color": false,
                  "table-headers": _vm.header,
                  "table-data": _vm.listData,
                  "no-data-text": "You currently don't have any tokens.",
                  loading: _vm.isLoadingData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }