var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c(
        "v-row",
        { attrs: { justify: "space-around", "no-gutters": "", dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("mew-module", {
                staticClass: "text-left height--full",
                attrs: {
                  "color-type": "overlayBg",
                  "has-body-padding": true,
                  subtitle: _vm.leftSideValues.subTitle,
                  title: _vm.leftSideValues.title,
                  caption: _vm.leftSideValues.caption,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("mew-module", {
                staticClass: "text-left height--full",
                attrs: {
                  "color-type": "overlayBg",
                  "has-body-padding": true,
                  subtitle: _vm.rightSideValues.subTitle,
                  title: _vm.rightSideValues.title,
                  caption: _vm.rightSideValues.caption,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mt-5" }, [
        _c("p", { staticClass: "mew-heading-3 text-left" }, [
          _vm._v(_vm._s(_vm.formText.title)),
        ]),
        _c("p", { staticClass: "mew-body pt-1 text-left" }, [
          _vm._v(" " + _vm._s(_vm.formText.caption) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("mew-input", {
            attrs: {
              value: _vm.amount,
              label: "Amount",
              "right-label": _vm.selectedToken.token,
              "hide-clear-btn": true,
              rules: _vm.checkIfNumerical,
              "error-messages": _vm.errorMessages,
            },
            on: { input: _vm.setAmount },
          }),
          _vm.showToggle
            ? _c("mew-toggle", {
                attrs: {
                  "button-group": _vm.group,
                  "button-type": "percentage",
                  "on-toggle-btn-idx": _vm.startingIdx,
                },
                on: { onBtnClick: _vm.onToggle },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-12 justify-center d-flex" },
        [
          _c("mew-button", {
            attrs: {
              title: _vm.buttonTitle.action,
              "color-theme": "primary",
              "btn-style": "background",
              "btn-size": "xlarge",
              disabled: _vm.disabled,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.emitValues.apply(null, arguments)
              },
            },
          }),
          _c("mew-button", {
            staticClass: "ml-2",
            attrs: {
              title: _vm.buttonTitle.cancel,
              "color-theme": "error",
              "btn-style": "transparent",
              "btn-size": "xlarge",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.cancel.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }