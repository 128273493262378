var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: _vm.title,
        close: _vm.resetToggle,
      },
    },
    [
      _c("aave-summary", {
        attrs: {
          "selected-token": _vm.preSelectedToken,
          "action-type": _vm.collateral,
          amount: _vm.tokenAmount,
        },
        on: { onConfirm: _vm.callSwitchCollateral },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }