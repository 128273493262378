var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--aave" },
    [
      _c("the-wrapper-dapp", {
        attrs: {
          "is-new-header": true,
          "dapp-img": _vm.headerImg,
          "banner-text": _vm.topBanner,
          "tab-items": _vm.tabs,
          "active-tab": _vm.activeTab,
          "external-contents": "",
          "on-tab": _vm.tabChanged,
          "valid-networks": _vm.validNetworks,
        },
        scopedSlots: _vm._u([
          {
            key: "tabContent1",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "mx-auto py-6 px-2 px-md-3",
                    attrs: { color: "transparent", "max-width": "724px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-end" },
                      [
                        _c("div", { staticClass: "mr-3" }, [
                          _vm._v("Health factor"),
                        ]),
                        !_vm.isLoadingData
                          ? _c(
                              "div",
                              {
                                staticClass: "font-weight-bold mr-3",
                                class: _vm.healthFactorColor,
                              },
                              [_vm._v(" " + _vm._s(_vm.healthFactor) + " ")]
                            )
                          : _c("v-skeleton-loader", {
                              staticClass: "mr-3",
                              attrs: { width: "31px", type: "text" },
                            }),
                        _c("mew-tooltip", {
                          attrs: { text: _vm.healthFactorTooltip },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mb-1 mt-2", attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bgWalletBlockDark pa-5 border-radius--5px",
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-2 font-weight-bold" },
                                [_vm._v("Aggregated Balance")]
                              ),
                              !_vm.isLoadingData
                                ? _c("h3", {}, [
                                    _vm._v(
                                      " " + _vm._s(_vm.totalLiquidity.usd) + " "
                                    ),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              !_vm.isLoadingData
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.totalLiquidity.eth) +
                                        " ETH "
                                    ),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              _c("v-divider", { staticClass: "my-4" }),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v("Composition")]
                                  ),
                                ]
                              ),
                              !_vm.isLoadingData
                                ? _c("mew-progress-bar", {
                                    staticClass: "mt-2",
                                    attrs: { data: _vm.compositionPercentage },
                                  })
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "12px", type: "text" },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bgWalletBlockDark pa-5 border-radius--5px height-100 d-flex flex-column",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex aling-center" },
                                [
                                  _c(
                                    "h5",
                                    { staticClass: "font-weight-bold mr-auto" },
                                    [_vm._v("Earnings")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center mr-3",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "circle pink mr-1",
                                          }),
                                          _vm._v(" APR "),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "circle lightblue mr-1",
                                          }),
                                          _vm._v(" Total "),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ma-auto text-center pa-5" },
                                [_vm._v("No data to show yet")]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "pt-md-2", attrs: { cols: "12" } },
                          [
                            _c("aave-table", {
                              ref: "depositsTable",
                              attrs: {
                                title: _vm.aaveTableTitle.balance_deposit,
                                "has-search": false,
                                "has-toggle": false,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center mt-9" },
                      [
                        _c("mew-button", {
                          attrs: { title: "Deposit", "btn-size": "xlarge" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleDepositOverlay(true)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tabContent2",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "mx-auto py-6 px-2 px-md-3",
                    attrs: { color: "transparent", "max-width": "724px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-end" },
                      [
                        _c("div", { staticClass: "mr-3" }, [
                          _vm._v("Health factor"),
                        ]),
                        !_vm.isLoadingData
                          ? _c(
                              "div",
                              {
                                staticClass: "font-weight-bold mr-3",
                                class: _vm.healthFactorColor,
                              },
                              [_vm._v(" " + _vm._s(_vm.healthFactor) + " ")]
                            )
                          : _c("v-skeleton-loader", {
                              staticClass: "mr-3",
                              attrs: { width: "31px", type: "text" },
                            }),
                        _c("mew-tooltip", {
                          attrs: { text: _vm.healthFactorTooltip },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mb-1 mt-2", attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bgWalletBlockDark pa-5 border-radius--5px",
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-2 font-weight-bold" },
                                [_vm._v("You Borrowed")]
                              ),
                              !_vm.isLoadingData
                                ? _c("h3", [
                                    _vm._v(_vm._s(_vm.totalBorrow.usd)),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              !_vm.isLoadingData
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.totalBorrow.eth) +
                                        " ETH "
                                    ),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              _c("v-divider", { staticClass: "my-4" }),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v("Composition")]
                                  ),
                                ]
                              ),
                              !_vm.isLoadingData
                                ? _c("mew-progress-bar", {
                                    staticClass: "mt-2",
                                    attrs: { data: _vm.borrowingsPercentage },
                                  })
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2 mb-0",
                                    attrs: { height: "12px", type: "text" },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bgWalletBlockDark pa-5 border-radius--5px",
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "mb-2 font-weight-bold" },
                                [_vm._v("Your Collateral")]
                              ),
                              !_vm.isLoadingData
                                ? _c("h3", [
                                    _vm._v(_vm._s(_vm.totalCollateral.usd)),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              !_vm.isLoadingData
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.totalCollateral.eth) +
                                        " ETH "
                                    ),
                                  ])
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "20px", type: "text" },
                                  }),
                              _c("v-divider", { staticClass: "my-4" }),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v("Composition")]
                                  ),
                                ]
                              ),
                              !_vm.isLoadingData
                                ? _c("mew-progress-bar", {
                                    staticClass: "mt-2",
                                    attrs: { data: _vm.collateralPercentage },
                                  })
                                : _c("v-skeleton-loader", {
                                    staticClass: "mt-2",
                                    attrs: { height: "12px", type: "text" },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "pt-md-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bgWalletBlockDark pa-5 loan-value-container",
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      _c(
                                        "span",
                                        { staticClass: "mew-heading-3" },
                                        [_vm._v("Loan to Value")]
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-end align-center",
                                        },
                                        [
                                          !_vm.isLoadingData
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "mew-heading-3",
                                                },
                                                [_vm._v(_vm._s(_vm.loanValue))]
                                              )
                                            : _c("v-skeleton-loader", {
                                                staticClass: "mt-2 mb-0",
                                                attrs: {
                                                  height: "19px",
                                                  width: "",
                                                  type: "text",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-md-2", attrs: { cols: "12" } },
                          [
                            _c("aave-table", {
                              ref: "borrowsTable",
                              attrs: {
                                title: _vm.aaveTableTitle.balance_borrow,
                                "has-search": false,
                                "has-toggle": false,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center mt-9" },
                      [
                        _c("mew-button", {
                          attrs: { title: "Borrow", "btn-size": "xlarge" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleBorrowOverlay(true)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("aave-deposit-overlay", {
        attrs: {
          open: _vm.showDepositOverlay,
          close: _vm.toggleDepositOverlay,
          "pre-selected-token": _vm.tokenSelected,
        },
      }),
      _c("aave-borrow-overlay", {
        attrs: {
          "pre-selected-token": _vm.tokenSelected,
          open: _vm.showBorrowOverlay,
          close: _vm.toggleBorrowOverlay,
        },
      }),
      _c("aave-collateral-overlay", {
        attrs: {
          open: _vm.showCollateralOverlay,
          close: _vm.closeCollateralOverlay,
          "pre-selected-token": _vm.tokenSelected,
        },
        on: {
          onConfirm: _vm.setCollateral,
          onClose: _vm.resetCollateralToggle,
        },
      }),
      _c("aave-withdraw-overlay", {
        attrs: {
          open: _vm.showWithdrawOverlay,
          close: _vm.closeWithdrawOverlay,
          "pre-selected-token": _vm.tokenSelected,
        },
      }),
      _c("aave-repay-overlay", {
        attrs: {
          open: _vm.showRepayOverlay,
          close: _vm.closeRepayOverlay,
          "pre-selected-token": _vm.tokenSelected,
        },
      }),
      _c("aave-set-apr-overlay", {
        attrs: {
          open: _vm.showAprTypeOverlay,
          close: _vm.closeAprTypeOverlay,
          "pre-selected-token": _vm.tokenSelected,
        },
        on: { onConfirm: _vm.setBorrowRate, onClose: _vm.resetAprToggle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }