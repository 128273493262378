var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        close: _vm.callClose,
        title: _vm.header,
        "content-size": "xlarge",
      },
    },
    [
      _vm.step === 0
        ? _c("aave-table", {
            attrs: {
              "is-loading-data": _vm.isLoadingData,
              "reserves-data": _vm.reservesData,
              "user-reserves-data": _vm.userSummary.userReservesData,
              title: _vm.depositTitle,
            },
            on: { selectedDeposit: _vm.handleSelectedDeposit },
          })
        : _vm._e(),
      _vm.step === 1
        ? _c("aave-amount-form", {
            attrs: {
              "selected-token": _vm.selectedToken,
              "show-toggle": _vm.aaveDepositForm.showToggle,
              "left-side-values": _vm.aaveDepositForm.leftSideValues,
              "right-side-values": _vm.aaveDepositForm.rightSideValues,
              "form-text": _vm.aaveDepositForm.formText,
              "button-title": _vm.aaveDepositForm.buttonTitle,
              "token-balance": _vm.tokenBalance,
              "token-decimal": _vm.selectedTokenDecimal,
            },
            on: { cancel: _vm.callClose, emitValues: _vm.handleDepositAmount },
          })
        : _vm._e(),
      _vm.step === 2
        ? _c("aave-summary", {
            attrs: {
              "selected-token": _vm.selectedToken,
              amount: _vm.amount,
              step: _vm.step,
              "action-type": _vm.depositTitle,
              "amount-usd": _vm.amountUSD,
            },
            on: { onConfirm: _vm.handleConfirm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }