var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-left" }, [
      _vm._v(
        " Select the type of rate for your loan. Please click on the desired rate type and read the info box for more information. "
      ),
    ]),
    _c(
      "div",
      { staticClass: "d-flex justify-center align-center mt-12 mb-3" },
      [
        _c(
          "v-card",
          {
            staticClass: "cursor-pointer d-flex flex-column py-6 px-8",
            attrs: {
              disabled: _vm.rates.stable === "--",
              flat: _vm.isStable,
              color: _vm.isStable ? "greyLight" : "white",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.setType(_vm.interestTypes.stable)
              },
            },
          },
          [
            _c("v-icon", { attrs: { "x-large": "", color: "textMedium" } }, [
              _vm._v("mdi-arrow-right-circle"),
            ]),
            _c("span", { staticClass: "textLight--text my-2" }, [
              _vm._v("Stable"),
            ]),
            _c("span", { staticClass: "mew-heading-3 textMedium--text" }, [
              _vm._v(_vm._s(_vm.rates.stable)),
            ]),
          ],
          1
        ),
        _c(
          "v-card",
          {
            staticClass: "cursor-pointer d-flex flex-column py-6 px-8 ml-5",
            attrs: {
              disabled: _vm.rates.variable === "--",
              flat: _vm.isVariable,
              color: _vm.isVariable ? "greyLight" : "white",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.setType(_vm.interestTypes.variable)
              },
            },
          },
          [
            _c("v-icon", { attrs: { "x-large": "", color: "orangePrimary" } }, [
              _vm._v("mdi-arrow-right-circle"),
            ]),
            _c("span", { staticClass: "textLight--text my-2" }, [
              _vm._v("Variable"),
            ]),
            _c("span", { staticClass: "mew-heading-3 orangePrimary--text" }, [
              _vm._v(_vm._s(_vm.rates.variable)),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex justify-center" },
      [
        _c("mew-button", {
          staticClass: "my-8",
          attrs: {
            title: "Continue",
            "btn-size": "xlarge",
            disabled: !_vm.apr.type,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onContinue.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }