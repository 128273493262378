var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: "Select your interest rate",
        close: _vm.resetToggle,
      },
    },
    [
      _c("aave-select-interest", {
        attrs: { "selected-token": _vm.selectedTokenDetails },
        on: { continue: _vm.handleSetInterestRate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }